// `Moengage` is defined globally in index.html on the web, but not in the apps.
// Wrap it in something else so we don't have check that it exists every time.
declare const Moengage: Record<string, any> | undefined;
const MoeWeb = typeof Moengage !== 'undefined' ? Moengage : undefined;

import { Device, DeviceInfo } from '@capacitor/device';
import { MoECapacitorCore, MoEDateTimeAttributes, MoELocationAttributes } from 'capacitor-moengage-core';

const MOENGAGE_APP_ID = '2YKS2RHGR24R7HE45EDOQDEA';

const NATIVE_PLATFORMS = ['android', 'ios'];

const propertiesForSessionEvents: Record<string, any> = {};

let deviceInfo: DeviceInfo | null = null;

export async function init() {
    deviceInfo ??= await Device.getInfo();
    if (NATIVE_PLATFORMS.includes(deviceInfo.platform)) {
        if (deviceInfo.platform === 'ios') {
            MoECapacitorCore.registerForPush();
        } else if (deviceInfo.platform === 'android') {
            await MoECapacitorCore.requestPushPermissionAndroid();
        }
        MoECapacitorCore.initialize({ appId: MOENGAGE_APP_ID });
    } else {
        // Web initialization is handled in index.html.
    }
}

export async function addPropertiesToMoEngageSessionEvents(properties: Record<string, any>) {
    Object.assign(propertiesForSessionEvents, properties);
}

export async function trackInMoEngage(
    eventName: string,
    properties: Record<string, any> = {},
    nonInteractive = false
) {
    deviceInfo ??= await Device.getInfo();
    if (NATIVE_PLATFORMS.includes(deviceInfo.platform)) {
        // Event properties in the native apps have to split up by type for some reason.
        const allPropertyEntries = Object.entries({ ...propertiesForSessionEvents, ...properties });

        const dateTimeAttributes: MoEDateTimeAttributes[] = [];
        const location: MoELocationAttributes['value'] = { longitude: NaN, latitude: NaN };

        const generalAttributes = allPropertyEntries.flatMap(([name, value]) => {
            if (value instanceof Date) {
                dateTimeAttributes.push({ name, value: value.toISOString() });
                return [];
            } else if (typeof value === 'number') {
                if (name === '$longitude') {
                    location.longitude = value;
                    return [];
                } else if (name === '$latitude') {
                    location.latitude = value;
                    return [];
                }
            }

            return [{ name, value }];
        });

        MoECapacitorCore.trackEvent({
            eventName,
            eventAttributes: {
                generalAttributes,
                dateTimeAttributes,
                locationAttributes: !isNaN(location.longitude) ? [{ name: 'Location', value: location }] : [],
                isNonInteractive: nonInteractive
            },
            appId: MOENGAGE_APP_ID,
        });
    } else {
        const allProperties = { ...propertiesForSessionEvents, ...properties };

        if ('$longitude' in allProperties && '$latitude' in allProperties) {
            allProperties['Location'] = {
                longitude: allProperties.$longitude,
                latitude: allProperties.$latitude,
            };
            delete allProperties.$longitude;
            delete allProperties.$latitude;
        }

        MoeWeb?.track_event(eventName, allProperties);
    }
}

export async function updateMoEngageUser(properties: Record<string, any> | null) {
    // Some known user properties have their own setters, so we'll mutate this object.
    const allProperties = { ...properties };

    deviceInfo ??= await Device.getInfo();
    if (NATIVE_PLATFORMS.includes(deviceInfo.platform)) {
        if (properties === null) {
            MoECapacitorCore.logoutUser({ appId: MOENGAGE_APP_ID });
            return;
        }

        if ('id' in allProperties) {
            MoECapacitorCore.setUniqueId({ uniqueId: allProperties.id, appId: MOENGAGE_APP_ID });
            delete allProperties.id;
        }

        if ('email' in allProperties) {
            MoECapacitorCore.setEmailId({ emailId: allProperties.email, appId: MOENGAGE_APP_ID });
            delete allProperties.email;
        }

        if ('mobilePhone' in allProperties) {
            MoECapacitorCore.setMobileNumber({ mobileNumber: allProperties.mobilePhone, appId: MOENGAGE_APP_ID });
            delete allProperties.mobilePhone;
        }

        if ('firstName' in allProperties) {
            MoECapacitorCore.setFirstName({ firstName: allProperties.firstName, appId: MOENGAGE_APP_ID });
            delete allProperties.firstName;
        }

        if ('lastName' in allProperties) {
            MoECapacitorCore.setLastName({ lastName: allProperties.lastName, appId: MOENGAGE_APP_ID });
            delete allProperties.lastName;
        }

        if ('$longitude' in allProperties && '$latitude' in allProperties) {
            MoECapacitorCore.setUserLocation({ location: {
                longitude: allProperties.$longitude,
                latitude: allProperties.$latitude,
            }, appId: MOENGAGE_APP_ID});
            delete allProperties.$longitude;
            delete allProperties.$latitude;
        }

        for (const [name, value] of Object.entries(allProperties)) {
            if (value instanceof Date) {
                MoECapacitorCore.setUserAttributeDate({ name, value: value.toISOString(), appId: MOENGAGE_APP_ID });
            } else {
                MoECapacitorCore.setUserAttribute({ name, value, appId: MOENGAGE_APP_ID });
            }
        }
    } else {
        if (properties === null) {
            MoeWeb?.destroy_session();
            return;
        }

        if ('id' in allProperties) {
            MoeWeb?.add_unique_user_id(allProperties.id);
            delete allProperties.id;
        }

        if ('mobilePhone' in allProperties) {
            MoeWeb?.add_mobile(allProperties.mobilePhone);
            delete allProperties.mobilePhone;
        }

        if ('email' in allProperties) {
            MoeWeb?.add_email(allProperties.email);
            delete allProperties.email;
        }

        if ('firstName' in allProperties) {
            MoeWeb?.add_first_name(allProperties.firstName);
            delete allProperties.firstName;
        }

        if ('lastName' in allProperties) {
            MoeWeb?.add_last_name(allProperties.lastName);
            delete allProperties.lastName;
        }

        if ('$longitude' in allProperties && '$latitude' in allProperties) {
            allProperties['Home LngLat'] = {
                longitude: allProperties.$longitude,
                latitude: allProperties.$latitude,
            };
            delete allProperties.$longitude;
            delete allProperties.$latitude;
        }

        for (const [key, value] of Object.entries(allProperties)) {
            MoeWeb?.add_user_attribute(key, value);
        }
    }
}
